<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="[]"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:chief_crew="{ item }">
        <div v-if="item.fleet_staff_assignment.length > 0">
          {{
            item.fleet_staff_assignment.find(
              (r) => r.staff_type === "chief_crew"
            )?.staff_name
          }}
        </div>
        <div v-else>
          N/A
        </div>
      </template>
      <template v-slot:driver="{ item }">
        <div v-if="item.fleet_staff_assignment.length > 0">
          {{
            item.fleet_staff_assignment.find((r) => r.staff_type === "driver")
              ?.staff_name
          }}
        </div>
        <div v-else>
          N/A
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isView"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete && item.fleet_staff_assignment?.length > 0"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-tag-remove
        </v-icon>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <select-date
            v-model="customFilters.date"
            :label="$t('select_date_*')"
            :min="dateValidation('min')"
            :max="dateValidation('max')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <!-- <create-staff-assignment
            v-if="isCreate"
            :filter-options="options"
          /> -->

          <edit-staff-assignment
            v-if="(isEdit || isView) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="{ ...selectedRow, ...options, ...customFilters }"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
            :msg="'Are you sure to Un-assign Cheif Crew and Driver'"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    // CreateStaffAssignment: () => import("./dialogs/CreateStaffAssignment.vue"),
    EditStaffAssignment: () => import("./dialogs/EditStaffAssignment.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),

    //
    selectDate: () => import("@/components/base/SingleDatePicker.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      // module_name: "csmsChargeStations", //vuex module name which define in Store Index
      permissions: {
        view: "staff-assignment.view",
        create: "staff-assignment.create",
        edit: "staff-assignment.edit",
        delete: "staff-assignment.delete",
      },
      //
      filters: {},
      subFilters: {},
      //
      customFilters: {
        date: this.getCurrentDate(),
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "staffAssignment/getLoading",
      meta: "staffAssignment/getMeta",
      list: "staffAssignment/getList",
    }),
    _headers() {
      const isICE = this.authUser.user_tech
        ? this.authUser.user_tech
        : this.tenantsList.find((r) => r.id == this.options.tenant_id)
            ?.vehicle_tech;

      const head = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "vehicle_id",
          width: "5%",
        },

        {
          text: this.$t("ev_make"),
          align: "left",
          sortable: false,
          value: "make",
          // width: "10%",
        },
        {
          text: this.$t("ev_model"),
          align: "left",
          sortable: false,
          value: "model",
          // width: "10%",
        },
        {
          text: this.$t("year"),
          align: "left",
          sortable: false,
          value: "year",
          // width: "10%",
        },
        {
          text: this.$t("chassis_no"),
          align: "left",
          sortable: false,
          value: "chassis_no",
          // width: "10%",
        },
        {
          text: this.$t("registration_no"),
          align: "left",
          sortable: false,
          value: "registration_no",
          // width: "10%",
        },
        ...(isICE !== "ice"
          ? [
              {
                text: this.$t("motor_no"),
                align: "left",
                sortable: false,
                value: "motor_no",
                // width: "10%",
              },
            ]
          : []),
        // {
        //   text: this.$t("motor_no"),
        //   align: "left",
        //   sortable: false,
        //   value: "motor_no",
        //   width: "10%",
        // },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
          // width: "10%",
        },
        {
          text: this.$t("crew_chief"),
          align: "left",
          sortable: false,
          value: "chief_crew",
          // width: "10%",
        },
        {
          text: this.$t("driver"),
          align: "left",
          sortable: false,
          value: "driver",
          // width: "10%",
        },
        // ...(this.authUser.user_type == null ||
        // this.authUser.user_nature == "oem" ||
        // this.authUser.isAdmin
        //   ? [
        //       {
        //         text: this.$t("business_name"),
        //         align: "left",
        //         sortable: false,
        //         value: "oem_b2b_business_name",
        //         width: "10%",
        //         showFleet: false,
        //       },
        //     ]
        //   : []),

        ...(this.$admin.hasAny([
          "staff-assignment.edit",
          "staff-assignment.delete",
        ])
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ];
      return head;
    },

    filterNames() {
      return [];
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "staff_assignment",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      const params = { ...this.options, ...this.customFilters };
      params.status = params.status
        ? params.status == "active"
          ? "1"
          : "0"
        : null;
      await this.$store.dispatch("staffAssignment/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row // Un Assigned  from Here
    async deleteSelectedRow(row) {
      try {
        const params = {
          id: row?.fleet_staff_assignment.map((r) => r.id),
        };
        await this.$store.dispatch("staffAssignment/destroy", params);
        this.$store.dispatch(
          "alerts/success",
          this.$t("unassigned_successfully")
        );
        this.fetchData();
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    //

    getCurrentDate() {
      const dt = new Date();
      const y = dt.getFullYear();
      let m = dt.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      let d = dt.getDate();
      d = d < 10 ? `0${d}` : d;

      return `${y}-${m}-${d}`;
    },
    dateValidation(v) {
      const d = new Date();
      let day = d.getDate();
      if (v === "max") day += 1;
      day = day < 10 ? `0${day}` : day;

      let m = d.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;

      const y = d.getFullYear();
      const value = `${y}-${m}-${day}`;
      return value;
    },
  },
};
</script>
